import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

import Stimulus from "../utils/stimulus"

import { analyticizeEvent } from "@oddcamp/analytics"

class CookieBar extends Controller {
  static values = { name: String }

  accept() {
    this.set(`accept`)
  }

  deny() {
    this.set(`deny`)
  }

  set = (value) => {
    Cookies.set(this.nameValue, value, { expires: 365 })
    this.element.classList.add(`hidden`)

    analyticizeEvent({
      data: {
        name: `Cookie consent`,
        props: {
          value: value,
        },
      },
    })
  }
}

Stimulus.register(`cookie-bar`, CookieBar)
