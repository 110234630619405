import { Controller } from "@hotwired/stimulus"

import Stimulus from "../utils/stimulus"

class Button extends Controller {
  static values = { disableOnFormSubmit: Boolean }

  static targets = [`text`]

  connect() {
    this.initDisableOnFormSubmit(true)

    document.documentElement.addEventListener(
      `turbo:before-visit`,
      this.beforeTurboVisit
    )
  }

  disconnect() {
    this.initDisableOnFormSubmit(false)

    document.documentElement.removeEventListener(
      `turbo:before-visit`,
      this.beforeTurboVisit
    )
  }

  initDisableOnFormSubmit = (on) => {
    if (this.disableOnFormSubmitValue) {
      const formEl = this.element.closest(`form`)
      if (formEl) {
        formEl[on ? `addEventListener` : `removeEventListener`](
          `submit`,
          this.disableOnFormSubmit
        )
      }
    }
  }

  disableOnFormSubmit = () => {
    this.setLoading(true)
  }

  beforeTurboVisit = () => {
    if (this.disableOnFormSubmitValue) {
      this.setLoading(false)
    }
  }

  setLoading = (loading = true) => {
    this.setDisabled(loading)
    this.element.classList.toggle(`button--loading`, loading)
  }

  setDisabled = (disabled = true) => {
    this.element.disabled = disabled
    this.element.classList.toggle(`button--disabled`, disabled)
  }

  setText = (text = ``) => {
    this.textTarget.innerHTML = text
  }

  setTextColor = (color) => {
    this.element.style.setProperty(`--text-color`, `var(--color-${color})`)
  }

  setFillColor = (color) => {
    this.element.style.setProperty(`--fill-color`, `var(--color-${color})`)
  }

  setOutlineColor = (color) => {
    this.element.style.setProperty(`--outline-color`, `var(--color-${color})`)
  }
}

Stimulus.register(`button`, Button)
