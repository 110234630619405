import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

import Stimulus from "../utils/stimulus"

class AvatarsItem extends Controller {
  static targets = [`trigger`, `content`]

  #tippy = null

  connect() {
    if (this.hasContentTarget) {
      this.#tippy = tippy(this.triggerTarget, {
        content: this.contentTarget.innerHTML,
        allowHTML: true,
        interactive: true,
        interactiveBorder: 4,
        animation: `shift-away`,
        appendTo: `parent`,
        popperOptions: {
          strategy: `fixed`,
        },
      })
    }
  }

  disconnect() {
    if (this.#tippy) this.#tippy.destroy()
  }
}

Stimulus.register(`avatars-item`, AvatarsItem)
