import { Controller } from "@hotwired/stimulus"

import Stimulus from "../utils/stimulus"
import { fetchApi } from "../utils/api"

import { analyticizeEvent } from "@oddcamp/analytics"

class OrganizationCard extends Controller {
  static values = {
    followed: Boolean,
    title: String,
    id: String,
    type: String,
    organizationType: String,
  }

  follow(e) {
    fetchApi({ url: e.params.url, method: `post` })
    this.followedValue = true

    analyticizeEvent({
      data: {
        name: `Follow`,
        props: {
          type: this.typeValue,
          title: this.titleValue,
          id: this.idValue,
          organizationType: this.organizationTypeValue,
        },
      },
    })
  }

  unfollow(e) {
    fetchApi({ url: e.params.url, method: `delete` })
    this.followedValue = false
  }

  askToJoin(e) {
    fetchApi({ url: e.params.url, method: `post` })
  }
}

Stimulus.register(`organization-card`, OrganizationCard)
